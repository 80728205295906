import {Directive, EventEmitter, Injectable, Output} from "@angular/core";
import { MainPageDetailModel } from "../models/plans.model";
import {PrimaryModel} from "../models/primary.model";

@Injectable()
export class EventsManager {
  @Output() showHideSubheader: EventEmitter<any> = new EventEmitter();
  @Output() officeUpdate: EventEmitter<any> = new EventEmitter();
  @Output() planDetailUpdate: EventEmitter<any> = new EventEmitter();
  @Output() levelSelected: EventEmitter<any> = new EventEmitter();
  @Output() feeScheduleUpdate: EventEmitter<any> = new EventEmitter();
  @Output() showPaymentScreen: EventEmitter<any> = new EventEmitter();
  @Output() showSuccessScreen: EventEmitter<any> = new EventEmitter();
  @Output() togglePrimary: EventEmitter<any> = new EventEmitter();
  @Output() sendSuccessfulPayment: EventEmitter<any> = new EventEmitter();
  @Output() sendPaymentError: EventEmitter<any> = new EventEmitter();
  @Output() sendPaymentTimeout: EventEmitter<any> = new EventEmitter();
  @Output() acceptMemberAgreement: EventEmitter<any> = new EventEmitter();
  @Output() setPurchaseStep: EventEmitter<any> = new EventEmitter();
  @Output() loadingState: EventEmitter<any> = new EventEmitter();
  @Output() getwWhoIsPrimary: EventEmitter<any> = new EventEmitter();
  @Output() memberCountUpdate: EventEmitter<any> = new EventEmitter();
  @Output() updateForm: EventEmitter<any> = new EventEmitter();
  @Output() hasMinor: EventEmitter<any> = new EventEmitter();
  @Output() taxPercUpdated: EventEmitter<any> = new EventEmitter();
  @Output() smsTokenEntered: EventEmitter<any> = new EventEmitter();

  docOrgId:string = '';
  orgId:string = '';
  officeInfo?: MainPageDetailModel = undefined;
  planDetailInfo: any = {};
  selectedLevel:any = {};
  feeScheduleInfo: any = {};
  applicants:any = {};
  showPrimary:boolean = false;
  showLoading:boolean = false;
  purchaseInfo:any = {};
  primaryMember: PrimaryModel = new PrimaryModel('', '', '', '', '', '', '', '', '', '', '', true);
  legalLanguage:any = {};
  whoIsPrimary:string = '';
  minorCount:number = 0;
  taxes: number = 0;
  constructor(){}

  subHeaderShowHide(evt: boolean){
    this.showHideSubheader.emit(evt);
  }

  getOfficeInfo(){
    let office:any = {};
    if(this.officeInfo?.name){
      office = this.officeInfo;
    } else if(sessionStorage['office']){
      office = JSON.parse(sessionStorage['office']);
      this.officeInfo = office;
    }

    return office;
  }

  getFeeScheduleInfo(){
    if(!this.feeScheduleInfo.pageName){
      this.setFeeScheduleInfo({});
    }

    return this.feeScheduleInfo;
  }

  getLegalLanguage(){
    return this.legalLanguage;
  }

  setOfficeInfo(office:MainPageDetailModel){
    sessionStorage['office'] = JSON.stringify(office);
    this.officeInfo = office;
    this.officeUpdate.emit(office);
  }

  setPlanDetails(details:any){
    sessionStorage['planDetails'] = JSON.stringify(details);
    this.planDetailInfo = details;
    this.planDetailUpdate.emit();
  }

  setLevelSelected(level:any){
    sessionStorage['level'] = JSON.stringify(level);
    this.selectedLevel = level;
    this.levelSelected.emit();
  }

  setWhoIsPrimary(member:string) {
    this.whoIsPrimary = member;
    this.getwWhoIsPrimary.emit(this.whoIsPrimary);
  }

  setTaxPerc(taxes:number){
    this.taxes = taxes;
    this.taxPercUpdated.emit(taxes);
  }

  getSelectedLevel(){
    let level:any = {}
    if(this.selectedLevel.benefitPlanCoverageLevelId){
      level = this.selectedLevel;
    } else {
      level = JSON.parse(sessionStorage['level']);
    }
    return level;
  }

  setFeeScheduleInfo(feeSchedule:any){
    if(this.feeScheduleInfo.practiceId){
      this.feeScheduleInfo = feeSchedule;
    } else if(sessionStorage['fees']){
      this.feeScheduleInfo = JSON.parse(sessionStorage['fees']);
    }
    //console.log('fees: ', JSON.parse(sessionStorage['fees']));
    this.feeScheduleUpdate.emit(this.feeScheduleInfo);
  }

  sendShowPaymentScreen(show:boolean){
    this.showPaymentScreen.emit(show);
  }

  sendShowSuccessScreen(show:boolean){
    this.showSuccessScreen.emit(show);
  }

  setApplicants(data:any){
    this.applicants = data;
  }

  setPrimaryApplicant(data:any):void {
    this.applicants.primaryApplicant = data;
  }

  setLoadingState(state:boolean){
    this.showLoading = state;
    this.loadingState.emit(this.showLoading);
  }

  setLegalLanguage(data:any){
    this.legalLanguage = data;
  }

  setMinorCount(count:number) {
    this.minorCount = count;
  }
}
