import { Component,  OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventsManager } from 'src/app/events/events.manager';
import { TimerPipe } from 'src/app/pipes/timer.pipe';
import { SalesService } from 'src/app/services/sales.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-generate-otp',
  templateUrl: './generate-otp.component.html',
  styleUrl: './generate-otp.component.scss'
})
export class GenerateOtpComponent {
  @Input() phoneNumber: string ="";
  @Input() emailAddress: string = "";
  @Output() OtpEntered = new EventEmitter<string>();
  
  timerWindow: number = 120;  //seconds
  timer: number = this.timerWindow;  //seconds
  isTimerOn: boolean = false;
  promise: any = {};
  errorMessage: string = '';
  otpErrorMessage: string = '';
  infoMessage: string = '';

  authenticateOTPForm = new FormGroup({
    otp: new FormControl('', [Validators.required,Validators.pattern('^[0-9]*$')])
  })
  
  constructor(private router: Router,private salesService:SalesService, private modal: NgbActiveModal, private em: EventsManager)
  {
    this.errorMessage = "";
    this.start();
  }

  ngOnInit(): void {
    
  }

  start() {
    this.timer = this.timerWindow;  //seconds
    this.isTimerOn = true;
    this.promise = setInterval(() => {
        this.timer--;
        if(this.timer == 0) {
            clearInterval(this.promise);
            this.isTimerOn = false;
        }
    }, 1000);
  }

  //resend the OTP to email address
  sendPurchaseOTP() {
    this.otpErrorMessage = "";
    if(this.emailAddress !== null && this.emailAddress !== '')
      {        
        this.salesService.sendPurchaseOTP(this.emailAddress)
        .subscribe({
          next: (data) =>
            {
              this.start();
              this.infoMessage = "The email is sent to your email address. Please check your email and enter the code below.";
              this.authenticateOTPForm.controls['otp'].setValue('');
            },
          error: (e) =>
            {
              this.otpErrorMessage = (e !=null && e.error != null) ? e.error[0].message : e.message;
              console.log('Error sending the Otp email');
            }
        });
      }
    }
  

    continuePurchase() {
      this.otpErrorMessage = "";
      if(this.authenticateOTPForm.controls['otp'].value)
      {
        this.em.smsTokenEntered.emit(this.authenticateOTPForm.controls['otp'].value);
        this.modal.close(this.authenticateOTPForm.controls['otp'].value);
      }
      else
      {
        this.otpErrorMessage = "Please enter the OTP code";
      }
    }
  
  translateError(error: string) {
    switch (error) {        
        case 'required':
            return 'OTP is required';
        case 'invalid':
            return 'OTP is invalid';
        default:
            return 'OTP is required';
    }
}
}
