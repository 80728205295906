import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, shareReplay } from "rxjs";
import { environment } from "src/environments/environment";
import { EventsManager } from "../events/events.manager";

export enum Features {
    AutoRenewalDisclaimer = 'purchase:auto-renewal-disclaimer',
    PurchaseSMSToken = 'purchase:purchase-sms-token'
  }
  
  /**
   * The feature flag observable.
   * This is used to cache the feature flags.
   */
  type FeatureFlagObservable = Observable<Record<string, boolean>>;
  
  /**
   * The feature flag service.
   * This service is used to get the feature flags for the current practice uiMode.
   */
  @Injectable()
  export class FeatureFlagService {
  
    /**
     * The cached feature flags observable array.
     * This is used to cache the feature flags.
     */
    cachedData$: FeatureFlagObservable[] = [];
    constructor(private http: HttpClient) {}
    getFeatureFlags(uiModeId: number): Observable<Record<string, boolean>> {
      const apiUrl = `${environment.apiUrl}${environment.treatmentApi}featureFlag/${uiModeId}` ;
  
      if (!this.cachedData$[uiModeId]) {
        this.cachedData$[uiModeId] = this.http.get<Record<string, boolean>>(apiUrl).pipe(
          map((reponse: any) => reponse),
          shareReplay(1)
        );
      }
  
      return this.cachedData$[uiModeId];
    }
  }