<form [formGroup]="cardForm" name="cardForm">
  <div class="row card">
    <div class="col-lg-12 col-12">
  <div class="row">
    <div class="col-12 header">
      Purchase Plan
    </div>
    <div class="row">
      <div class="col-12">
        <hr>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 sub-header mt-1 mb-3">
      Card Details
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <input type="checkbox" formControlName="memAgree" (change)="setMemAgreement()" value="{{accepted}}" required>&nbsp;<label class="member-agreement-gray">Click Here to Agree to the</label>&nbsp; <label class="member-agreement-blue" (click)="openMemberAgreement()">MEMBER AGREEMENT</label>
      <div *ngIf="!accepted" class="warning">
        (This field is required to continue your payment.)
      </div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-12">
      <credit-card-field
        [(field)]="formGroup['cardNumber']"
        (cardNumberChange)="onCardNumberChange($event)"
        [props]="{
                        id: 'cardNumber',
                        label: 'Card Number',
                        required: true,
                        validator: creditCardSchema
                    }"
        class="field col-12"
      />
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-6">
      <label for="expiration">Expiration Date</label>
      <input type="text" id="expiration" name="expiration" class="text-input" formControlName="expDate" maxlength="5" (keyup)="expFormat($event)" required>
    </div>
    <div class="col-6">
      <label for="cvv">Security Code</label>
      <input id="cvv" name="cvv" class="form-control" [hidden]="true" formControlName="cvv" />
      <input type="text" id="hiddenCvv" name="hiddenCvv" min="3" max="4" class="text-input" formControlName="hiddenCvv"
           (blur)="cvvBlur()" (keyup)="cvvKeyPress()"  (focus)="cvvFocus()" required>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-6">
      <label for="firstName">First Name</label>
      <input type="text" id="firstName" name="firstName" class="text-input" formControlName="firstName" required>
    </div>
    <div class="col-6">
      <label for="lastName">Last Name</label>
      <input type="text" id="lastName" name="lastName" class="text-input" formControlName="lastName" required>
    </div>
  </div>
  <div class="row" *ngIf="currentPurchase.autoRenewalDetail.autoRenewalOffered && showAutoRenewalDisclaimer">
    <div class="col-lg-12 col-12">
      <input type="checkbox" formControlName="showAutoRenewalDisclaimer" [(ngModel)]="autoRenewalRequired" >
      <span class="checkbox-text"> I consent to the automatic renewal of this
          Discount Dental Plan.</span>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-12">
        <input type="checkbox" id="commAgreement" formControlName="commAgreement" name="commAgreement" value="true" checked>&nbsp;
        <span for="commAgreement" class="member-agreement-gray">I agree to receiving promotional plan communication via E-mail and/or text. Changes to your selection can be made inside the Member Portal</span>
        <!-- <label for="commAgreement" class="member-agreement-gray">I agree to receiving promotional plan communication via E-mail and/or text. Changes to your selection can be made inside the Member Portal</label> -->
    </div>
  </div>
  <div class="row" *ngIf="isMedicalRequired">
    <div class="col-lg-12 col-12 mt-1">
     <input type="checkbox" formControlName="isMedicalConfirmed" (change)="onMedicalChanged()" value="{{isMedicalConfirmed}}" required>
     &nbsp;<span for="isMedicalConfirmed" class="member-agreement-gray">I acknowledge that I do not qualify, nor do I have Medi-Cal Insurance. Individuals that have Medi-Cal Insurance are not eligible to purchase this plan.</span>
    </div>
    <div class="col-lg-12 col-12">
      <hr>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-2 mt-3">
      <div class="billing-header">
        Billing Address
      </div>
    </div>
    <div class="col-12" *ngIf="currentPurchase">
      <div class="address">
        {{billingAddress.address}} <br>
        {{billingAddress.city}},  {{billingAddress.state}}  {{billingAddress.zipCode}}
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-lg-3">&nbsp;</div>
    <div class="col-lg-4 col-12">
      <div class="m-auto">
        <re-captcha id="recaptcha" class="recaptcha-layout" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" siteKey="{{captcha}}"
         [ngModelOptions]="{standalone: true}" [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
        <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback">
          <div *ngIf="recaptcha.errors?.['required']">This field is required.</div>
        </div>
      </div>
    </div>
    <div class="col-lg-3">&nbsp;</div>
  </div>

    <div class="row mt-4">
      <div class="col-lg-12 col-12">
          <span class="btn-container" style="width: 100%">
            <button class="btn submit-btn" (click)="authenticateUser()" [disabled]="cardForm.invalid">Purchase Plan</button>
          </span>
      </div>

      <div *ngIf="showError" class="col-lg-12 col-12 warning">
        {{errorMessage}}
      </div>
      </div>
    </div>
  </div>
  <input type="hidden" id="accountNumber" formControlName="accountNumber"/>
  <input type="hidden" id="securityCode" formControlName="securityCode"/>
  <input type="hidden" id="response$paypageRegistrationId" name="response$paypageRegistrationId" formControlName="response$paypageRegistrationId" readOnly="true" value="" />
  <input type="hidden" id="response$bin" name="response$bin" formControlName="response$bin" readOnly="true" />
</form>
