<div class="row card-container modal-wf-lg">
    <div class="col-12"> 
        <div class="container container-align" >
            <div class="row layout">
                <img class="icon" src="assets/images/mail.svg" alt="Send Code">                
                        <div class="col-12">                
                            <h2 class="header">Verification Code</h2>
                        </div>
                        <div class="col-12">
                            <p class="info">Verification code has been sent to your phone number +1 {{this.phoneNumber | phone}}</p>
                        </div> 
                <div class="col-12">
                    <form [formGroup]="authenticateOTPForm" id="authenticateOTPForm" name="authenticateOTPForm">        
                        <div class="form-group">
                            <input class="form-control text-center text-dark" maxlength="6" formControlName="otp" id="otp" name="otp" required/>                            
                            <div *ngIf="authenticateOTPForm.get('otp')?.dirty && authenticateOTPForm.get('otp')?.invalid" class="warning">
                                <div *ngIf="authenticateOTPForm.get('otp')?.errors">
                                    <div class="mt-1" *ngFor="let error of authenticateOTPForm.get('otp')?.errors| objToKeys">
                                      {{translateError(error)}}
                                    </div>
                                </div>
                            </div>                            
                        </div>                        
                        <div class="smalltext text-center p-5">
                        <span >I didn't receive a code <a [style.cursor]="timer > 0 ? 'none' :'auto'" (click)="sendPurchaseOTP()" [ngClass]="{'block-resend': timer > 0 }">Resend</a></span> <span><img id="timer-icon" class="timer-icon" src="assets/images/timer.svg" />
                                <span [ngClass]="{'time-critical': timer < 10}"> {{timer | timer | date:'m:ss'}} </span>                        
                            </span>                
                        </div>
                         <button class="btn-block button container" [disabled]="authenticateOTPForm.invalid" type="submit" (click)="continuePurchase()" >CONTINUE</button>    
                    </form>
                    <div class="smalltext text-center p-2">
                        <span *ngIf="otpErrorMessage" class="warning">{{otpErrorMessage}}</span>
                        <span *ngIf="infoMessage" class="success">{{infoMessage}}</span>
                    </div>
                </div>              
            </div>
        </div>               
    </div>
  </div>
  
